const apiUrl =
  process.env.REACT_APP_API_BASE_URL || 'https://api.semanticpulse.no';

export const streamFromLLM = async function* (authorizationToken, chat) {
  const systemMessage = {
    role: 'system',
    text: `Være en profesjonell lege som gjennomfører en legemiddelgjennomgang. Du får sendt pasientens alder, kjønn, liste over diagnoser,  liste over observasjoner med dato og pasients medisinliste.

    På bakgrunn av dette gjennomfør en legemiddelgjennomgang av medisinene og vurder om det er noen spesielle henysn mtp pasientens diagnoser, observasjoner, alder og interaksjoner mellom medisinene pasienten står på. 
    
    Vær veldig kort og konsis og presenter medisinene i en liste med etterfulgende vurdering. 
    Bruk en logisk struktur og vær veldig ryddig. Ikke gi informasjon utover. 
    
    Følg denne sktrukturen: 
    - Legemiddel 1. Vurdering av legemiddelet.
    - Legemiddel 2. Vurdering av legemiddelet.
    - Legemiddel 3. Vurdering av legemiddelet.
    
    
    Eksempel på et svar: 
    
    1. Amitriptylin 50 mg x 1 (Dose: 45): Vær forsiktig med bruk hos eldre grunnet økt risiko for kognitiv svikt og fall. 
    2. Diazepam 5 mg x 1 (Dose: 500): Bør unngås hos eldre grunnet økt risiko for sedering og fall. Kan påvirke nyrefunksjon og bør doseres forsiktig.
    3. Paracetamol 1 g x 1 (Dose: 1 g): Kan brukes trygt hos eldre ved riktig dosering. 
    4. Pramipeksoldihydrokloridmonohydrat 88 mikrog x 1 (Dose: 500): Bruk med forsiktighet hos eldre pga økt risiko for ortostatisk hypotensjon. Kan påvirke nyrefunksjon.
    5. Dabigatraneteksilatmesilat 110 mg x 1 (Dose: 200): Dosen bør justeres ved nedsatt nyrefunksjon, følg opp glomerulær filtreringsrate regelmessig.`,
  };

  console.log('Calling API with chat:', [systemMessage, ...chat]);

  try {
    const response = await fetch(`${apiUrl}/api/v1/chat`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authorizationToken}`,
      },
      body: JSON.stringify({ chat: [systemMessage, ...chat] }),
    });

    if (!response.ok) {
      throw new Error(`API call failed with status: ${response.status}`);
    }

    const reader = response.body?.getReader();
    const decoder = new TextDecoder('utf-8');
    let result;
    while (!(result = await reader.read()).done) {
      const chunk = decoder.decode(result.value, { stream: true });
      console.log('Yielding chunk:', chunk);
      yield chunk;
    }
  } catch (error) {
    console.error('Error while calling API:', error);
    throw error;
  }
};
