// KI-assist-chat.jsx

import React, { useEffect, useState, useRef } from 'react';
import { createThread, sendMessage } from './KI-assist-api';
import Markdown from 'react-markdown';
import './styles.css';

const GptChat = ({ prompt, onClose }) => {
  const [chat, setChat] = useState([]);
  const [loading, setLoading] = useState(false);
  const [threadId, setThreadId] = useState(null);
  const endOfMessagesRef = useRef(null);
  const [selectedCondition, setSelectedCondition] = useState('');
  const [isFirstMessage, setIsFirstMessage] = useState(true);

  // Ensure we scroll to the bottom when new messages are added
  useEffect(() => {
    if (endOfMessagesRef.current) {
      endOfMessagesRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [chat]);

  // Send the initial message
  useEffect(() => {
    const sendInitialMessage = async () => {
      if (prompt && isFirstMessage) {
        setLoading(true);
        try {
          const newThreadId = await createThread();
          console.log('New thread created:', newThreadId);
          setThreadId(newThreadId);
          await handleStreamResponse(newThreadId, prompt);
          setIsFirstMessage(false);
        } catch (error) {
          console.error('Failed to send initial message:', error);
          setLoading(false);
        }
      }
    };
    sendInitialMessage();
  }, [prompt, isFirstMessage]);

  const handleStreamResponse = async (threadId, messageContent) => {
    setLoading(true);
    const stream = await sendMessage(threadId, messageContent);
    const reader = stream.getReader();
    let currentText = ''; // This will store the current complete message
  
    const read = async () => {
      const { done, value } = await reader.read();
      if (done) {
        setLoading(false); // Stop the loader when streaming is done
        console.log('Stream finished.');
        return;
      }
      const chunk = new TextDecoder().decode(value);
      currentText += chunk; // Append new chunk to current text
      setChat((prevChat) => {
        const lastMessage = prevChat[prevChat.length - 1];
        if (lastMessage && lastMessage.isBot) {
          return [...prevChat.slice(0, -1), { isBot: true, msg: currentText }];
        } else {
          return [...prevChat, { isBot: true, msg: currentText }];
        }
      });
      return read(); // Continue reading
    };
  
    await read();
  };
  
  

  // Handle sending messages
  const handleSendMessage = async (messageContent = selectedCondition) => {
    if (!messageContent.trim()) return;
    setLoading(true);

    setChat((prevChat) => [...prevChat, { isBot: false, msg: messageContent }]);

    if (!threadId) {
      try {
        const newThreadId = await createThread();
        setThreadId(newThreadId);
        await handleStreamResponse(newThreadId, messageContent);
      } catch (error) {
        console.error('Failed to create a thread or send message:', error);
        setLoading(false);
      }
    } else {
      try {
        await handleStreamResponse(threadId, messageContent);
      } catch (error) {
        console.error('Failed to send message:', error);
        setLoading(false);
      }
    }

    setSelectedCondition('');
  };

  // Handle key down events for the textarea
  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSendMessage();
    }
  };

  return (
    <div>
      <div className="chat-history">
        {chat.map((message, index) => (
          <div key={index} className={message.isBot ? 'bot-message' : 'user-message'}>
            <Markdown>{message.msg}</Markdown>
          </div>
        ))}
        {loading && (
          <div className="loader">
            <span className="loader-dot"></span>
            <span className="loader-dot"></span>
            <span className="loader-dot"></span>
          </div>
        )}
        <div ref={endOfMessagesRef} />
      </div>
      {!isFirstMessage && !loading && (
        <div className="chat-input-container">
          <textarea
            value={selectedCondition}
            onChange={(e) => setSelectedCondition(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder="Skriv din melding her..."
            className="chat-textarea"
          />
          <button
            onClick={() => handleSendMessage(selectedCondition)}
            className="send-button"
            disabled={loading || !selectedCondition.trim()}
          >
            Send
          </button>
        </div>
      )}
    </div>
  );
};

export default GptChat;
