import React, { useState, useRef, useEffect, useCallback } from 'react';
import Markdown from 'react-markdown';
import { streamFromLLMmed } from './KI-chatboxIndexMed';
import "./styles.css";
import {useAuthToken} from "../context/useAuthToken";

const GptChatMedStream = ({ prompt, onClose }) => {
  const [userText, setUserText] = useState('');
  const [conversation, setConversation] = useState([]);
  const [loading, setLoading] = useState(false);
  const [firstMessageSent, setFirstMessageSent] = useState(false);
  const chatRef = useRef(null);
  const botIsTyping = conversation.length > 0 && conversation[conversation.length - 1].inProgress;
  const [reasonForSwitching, setReasonForSwitching] = useState('');
  const getToken = useAuthToken()

  useEffect(() => {
    chatRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [conversation]);

  const sendInitialMessage = useCallback(async () => {
    if (prompt && reasonForSwitching) {
      const initialMessage = `Jeg ønsker alternativer til denne medisinen på grunn av:\n${reasonForSwitching} \n\n ${prompt}`;
      const displayMessage = `${reasonForSwitching}`;
      const newConversation = [
        {
          role: 'user',
          text: displayMessage,
          inProgress: false,
          hidden: false // Show first user message
        },
      ];

      setConversation([
        ...newConversation,
        {
          role: 'assistant',
          text: '',
          inProgress: true,
        },
      ]);




      

      let buffer = '';
      setLoading(true);
      setFirstMessageSent(true);
      try {
        const chunkStream = await streamFromLLMmed(await getToken(), [
          {
            role: 'user',
            text: initialMessage,
            inProgress: false,
            hidden: true // Hide the message sent to the assistant
          },
        ]);
        chatRef.current?.scrollTo(0, chatRef.current.scrollHeight);
        for await (let chunk of chunkStream) {
          buffer += chunk;
          updateLastConversationMessage(buffer, false);
          chatRef.current?.scrollIntoView({ behavior: 'smooth' });
        }
        updateLastConversationMessage(buffer, true);
      } catch (error) {
        console.error('Error in sendInitialMessage:', error);
      } finally {
        setLoading(false);
      }
    }
  }, [prompt, reasonForSwitching, getToken]);

  const updateLastConversationMessage = (text, done) => {
    setConversation((conversation) => [
      ...conversation.slice(0, -1),
      { role: 'assistant', text, inProgress: !done },
    ]);
  };

  const postChatMessage = async () => {
    if (botIsTyping) return;

    const userMessage = userText;
    const newConversation = [
      ...conversation,
      {
        role: 'user',
        text: userMessage,
        inProgress: false,
        hidden: false // Normal user message
      },
    ];

    setConversation([
      ...newConversation,
      {
        role: 'assistant',
        text: '',
        inProgress: true,
      },
    ]);

    setUserText(''); // Reset input field
    let buffer = '';
    setLoading(true);
    try {
      const chunkStream = await streamFromLLMmed(await getToken(), [
        {
          role: 'user',
          text: userMessage,
          inProgress: false,
          hidden: true // Hide the message sent to the assistant
        },
      ]);
      chatRef.current?.scrollIntoView({ behavior: 'smooth' });
      for await (let chunk of chunkStream) {
        buffer += chunk;
        updateLastConversationMessage(buffer, false);
        chatRef.current?.scrollIntoView({ behavior: 'smooth' });
      }
      updateLastConversationMessage(buffer, true);
    } catch (error) {
      console.error('Error in postChatMessage:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      if (!firstMessageSent) {
        sendInitialMessage();
      } else {
        postChatMessage();
      }
    }
  };

  return (
    <div>
      <div className="chat-history">
        {conversation.map((message, index) => (
          !message.hidden && (
            <div key={index} className={message.role === 'assistant' ? 'bot-message' : 'user-message'}>
              <Markdown>{message.text}</Markdown>
            </div>
          )
        ))}
       
        <div ref={chatRef} />
      </div>
      <div className="chat-input-container">
        <textarea
          value={firstMessageSent ? userText : reasonForSwitching}
          onChange={(e) => firstMessageSent ? setUserText(e.target.value) : setReasonForSwitching(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder={!firstMessageSent ? "Skriv inn årsak til legemiddelbytte" : "Skriv din melding her..."}
          className="chat-textarea"
        />
        <button 
          onClick={!firstMessageSent ? sendInitialMessage : postChatMessage} 
          className="send-button"
          disabled={loading || (!reasonForSwitching.trim() && !firstMessageSent)}
        >
          Send
        </button>
      </div>
    </div>
  );
};

export default GptChatMedStream;
