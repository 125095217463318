import React, { useState, useRef, useEffect, useCallback } from 'react';
import Markdown from 'react-markdown';
import "./styles.css"; // Importer stilene som ble brukt i GptChat

const ChatBox = ({ askLLM, medicalText, autoSendFirstMessage }) => {
  const [userText, setUserText] = useState('');
  const [conversation, setConversation] = useState([]);
  const [loading, setLoading] = useState(false);
  const [firstMessageSent, setFirstMessageSent] = useState(false);
  const chatRef = useRef(null);
  const botIsTyping = conversation.length > 0 && conversation[conversation.length - 1].inProgress;

  useEffect(() => {
    chatRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [conversation]);

  const sendInitialMessage = useCallback(async () => {
    const initialMessage = medicalText ? `\n\nDET FØLGENDE EN EN MEDISINSK TEKST FRA FELLESKATALOGEN:\n${medicalText}` : '';
    const newConversation = [
      {
        role: 'user',
        text: initialMessage,
        inProgress: false,
        hidden: true // Mark first user message as hidden
      },
    ];

    setConversation([
      ...newConversation,
      {
        role: 'assistant',
        text: '',
        inProgress: true,
      },
    ]);

    let buffer = '';
    setLoading(true);
    setFirstMessageSent(true);
    try {
      const chunkStream = await askLLM(newConversation);
      chatRef.current?.scrollTo(0, chatRef.current.scrollHeight);
      for await (let chunk of chunkStream) {
        buffer += chunk;
        updateLastConversationMessage(buffer, false);
        chatRef.current?.scrollTo(0, chatRef.current.scrollHeight);
      }
      updateLastConversationMessage(buffer, true);
    } catch (error) {
      console.error('Error in sendInitialMessage:', error);
    } finally {
      setLoading(false);
    }
  }, [askLLM, medicalText]);

  useEffect(() => {
    if (autoSendFirstMessage && !firstMessageSent) {
      sendInitialMessage();
    }
  }, [autoSendFirstMessage, firstMessageSent, sendInitialMessage]);

  const updateLastConversationMessage = (text, done) => {
    setConversation((conversation) => [
      ...conversation.slice(0, -1),
      { role: 'assistant', text, inProgress: !done },
    ]);
  };

  const postChatMessage = async () => {
    if (botIsTyping) return;

    const userMessage = userText;
    const newConversation = [
      ...conversation,
      {
        role: 'user',
        text: userMessage,
        inProgress: false,
        hidden: false // Normal user message
      },
    ];

    setConversation([
      ...newConversation,
      {
        role: 'assistant',
        text: '',
        inProgress: true,
      },
    ]);

    setUserText('');
    let buffer = '';
    setLoading(true);
    try {
      const chunkStream = await askLLM(newConversation);
      chatRef.current?.scrollTo(0, chatRef.current.scrollHeight);
      for await (let chunk of chunkStream) {
        buffer += chunk;
        updateLastConversationMessage(buffer, false);
        chatRef.current?.scrollTo(0, chatRef.current.scrollHeight);
      }
      updateLastConversationMessage(buffer, true);
    } catch (error) {
      console.error('Error in postChatMessage:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      postChatMessage();
    }
  };

  return (
    <div>
      <div className="chat-history">
        {conversation.map((message, i) => (
          !message.hidden && (
            <div key={i} className={message.role === 'assistant' ? 'bot-message' : 'user-message'}>
              <Markdown>{message.text}</Markdown>
            </div>
          )
        ))}

        <div ref={chatRef} />
      </div>
      <div className="chat-input-container">
        <textarea
          value={userText}
          onChange={(e) => setUserText(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder="Skriv din melding her..."
          className="chat-textarea"
        />
        <button 
          onClick={postChatMessage} 
          className="send-button"
          disabled={botIsTyping || loading || !userText.trim()}
        >
          Send
        </button>
      </div>
    </div>
  );
};

export default ChatBox;
