const apiUrl =
  process.env.REACT_APP_API_BASE_URL || 'https://api.semanticpulse.no';

export const streamFromLLMmed = async function* (authorizationToken, chat) {
  const systemMessage = {
    role: 'system',
    text: `Vær en profesjonell lege som kommer med alternativer til det aktuelle legemiddelet basert på årsaken til at det bør byttes og pasientinformasjonen du får oppgitt. Det er svært viktig at du tar allergier med i beregningen. ALDRI foreslå medisiner som pasienten er allergisk mot! Du må for eksempel ALDRI foreslå paracet som et alternativ dersom det i problemlisten står "allergi mot paracetamol". 

    Du får tilgang til pasientens alder, kjønn, liste over diagnoser, liste over observasjoner med dato og pasientens medisinliste. Ta en helhetsvurdering basert på pasientinformasjonen du får tilsendt, samt indikasjon for medisinen og årsak til medisinbytte oppgitt av bruker. Husk å bekrefte indikasjon før du gir noen råd om legemiddelbytte. Vær veldig påpasselig med å IKKE anbefale medisiner pasienten er allergisk mot, resistent mot eller medisiner som er kontraindisert.
    
    Gi en liste over konkrete legemidler med begrunnelse. Bruk alltid punktlister og oppgi kort begrunnelse for hvert alternativ. Snakk alltid norsk, oppretthold en nøytral og profesjonell tone. Bruk felleskatalogen som kilde.
    
    Ved oppfølgingsspørsmål fra bruker, svar på disse så godt du kan. Vær kortfattet og bruk en nøytral og profesjonell tone.
    
    **Eksempel på hvordan du skal håndtere allergier:**
    
    Hvis pasienten har allergi mot paracetamol, foreslå aldri paracetamol eller medisiner som inneholder paracetamol.
    `,
  };

  console.log('Calling API with chat:', [systemMessage, ...chat]);

  try {
    const response = await fetch(`${apiUrl}/api/v1/chat`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authorizationToken}`,
      },
      body: JSON.stringify({ chat: [systemMessage, ...chat] }),
    });

    if (!response.ok) {
      throw new Error(`API call failed with status: ${response.status}`);
    }

    const reader = response.body?.getReader();
    const decoder = new TextDecoder('utf-8');
    let result;
    while (!(result = await reader.read()).done) {
      const chunk = decoder.decode(result.value, { stream: true });
      console.log('Yielding chunk:', chunk);
      yield chunk;
    }
  } catch (error) {
    console.error('Error while calling API:', error);
    throw error;
  }
};
