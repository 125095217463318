import React from "react";
import { Card } from "react-bootstrap";
import "./Cds.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes /*, faExclamationTriangle*/ } from "@fortawesome/free-solid-svg-icons";
import parse from 'html-react-parser'; // Du trenger å installere denne pakken
import kidneySymbolImage from './assets/nyre.png';


export const CdsKidneyComponent = ({ kidneyResponseData, patientAge, closeCardKidney, showKidneyCard }) => {
   console.log('CdsResponseData received:', kidneyResponseData); // Legg til for å se hva som faktisk blir sendt til komponenten
   
     

   // Definerer en liste over medikamentkategorier som kun skal vises for pasienter over 65
   const ageSensitiveSummaries = [
    "<div xmlns=\"http://www.w3.org/1999/xhtml\">TCA (trisykliske antidepressiver)</div>",
    "<div xmlns=\"http://www.w3.org/1999/xhtml\">Antipsykotika (nevroleptika)</div>",
    "<div xmlns=\"http://www.w3.org/1999/xhtml\">Antihistaminer – 1. generasjon</div>",
    "<div xmlns=\"http://www.w3.org/1999/xhtml\">Antikolinerge midler mot overaktiv blære</div>",
    "<div xmlns=\"http://www.w3.org/1999/xhtml\">Sulfonylurea</div>"
  ];

  // Sjekk om dataene er tilgjengelige og at cards er et array
  if (!kidneyResponseData || !Array.isArray(kidneyResponseData.cards)) {
    // Her kan du legge til en feilmelding eller en lasteindikator
    return null;
  }

  console.log('Pasientalder:', patientAge);

  // Filtrer kortene basert på pasientens alder og summary-betingelsene
  const filteredCards = kidneyResponseData.cards.filter(card => {
    console.log('Kortets summary:', card.summary);
    const isAgeSensitive = ageSensitiveSummaries.some(summary => {
      const isContained = card.summary.includes(summary);
      console.log(`Sjekker om "${summary}" er i summary:`, isContained);
      return isContained;
    });
    const shouldDisplayCard = patientAge > 65 ? true : !isAgeSensitive;
    console.log('Skal kortet vises?', shouldDisplayCard);
    return shouldDisplayCard;
  });



  return (
    <div>
        {showKidneyCard && filteredCards.map((cdsCard, idx) => {
        // Sjekk om kortets summary inneholder noen av de alder-sensitive overskriftene
        const isAgeSensitiveCard = ageSensitiveSummaries.some(summary =>
          cdsCard.summary.includes(summary)
        );


        const category = cdsCard.extension["no.felleskatalogen.cds.nedsatt-nyre.kategori"];
        const subcategory = cdsCard.extension["no.felleskatalogen.cds.nedsatt-nyre.underkategori"];

        // Velg riktig overskrift basert på om kortet er alder-sensitivt
        const cardHeader = isAgeSensitiveCard && patientAge > 65
          ? "Legemiddelet bør unngås da pasienten er over 65 år"
          :  `${category} - ${subcategory}`;

        return (
          <Card className="cdsModal" key={`cds-card-${idx}`}>
            <Card.Header>
              <img src={kidneySymbolImage} alt="Nyresymbol" className="text-kidney4" />
              <span>{cardHeader}</span>
              <button onClick={closeCardKidney} className="close-button">
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </Card.Header>
            <Card.Body className="cdsResponseBody">
              <h4 className="cdsResponseMainTitle">{parse(cdsCard.summary)}</h4>
              {cdsCard.detail && <div className="cdsResponseDetail">{parse(cdsCard.detail)}</div>}
              <div className="cdsResponseFooter">
              {/*}  <span>Kilde:</span> 
                <a href="https://www.helsebiblioteket.no/innhold/artikler/legemidler/legemiddelaktuelt/sjekkliste-for-for-legemiddelsamstemming-og-legemiddelgjennomgang#:~:text=Noen%20legemidler%20med%20risiko%20for%20alvorlige%20bivirkninger%3A" 
                   target="_blank" 
                   rel="noopener noreferrer">
                   Sjekkliste for legemiddelsamstemming og legemiddelgjennomgang
                </a>*/}
                <div className="cdsResponseProvider">
                  <span>Tjenesten leveres av</span> 
                  <a href={cdsCard.source.url} 
                     target="_blank" 
                     rel="noopener noreferrer"
                     className="cdsResponseLink">
                    {cdsCard.source.label}
                  </a>
                </div>
              </div>
            </Card.Body>
          </Card>
        );
      })}
    </div>
  );
};

export default CdsKidneyComponent;



